<template>
  <HelloWorld />
  <footer class="footer">
    <div class="container">
      <img
        class="footer-logo"
        src="https://cdn.nishikino.xyz/rainboom-studio/static/rbs_footerLogo.png"
        alt="Rainboom Studio Logo"
      />
      <p class="text-center color-dark">
        &copy; 2017 - 2021 Rainboom Studio <br />
        Rainboom Studio and all associated content is owned by <a href="https://Asthriona.com" target="_blank" rel="noopener noreferrer">Asthriona ltd.</a>.
      </p>
    </div>
  </footer>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld
  }
};
</script>

<style>
html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: rgb(49, 49, 49);
}
#app {
  background-color: rgb(49, 49, 49) !important;
  color: white !important;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.footer {
  background-color: #000000;
  color: white;
  padding: 10px;
  position: relative;
  bottom: 0;
  width: 100%;
}
.footer-logo {
  margin: 0 auto;
  width: auto;
  height: 100px;
  text-align: center;
  display: flex;
}
.text-center {
  text-align: center;
}
.color-dark {
  color: #2e2d2d;
}
.color-dark a:link, a:visited, a:active {
  color: #2e2d2d;
  transition: 1s;
}
a:hover {
  color: #e68a00;
  transition: 1s;
}
</style>
