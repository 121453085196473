<template>
  <section class="header text-center">
    <div class="header-content-inner">
      <h1>Rainboom Studio</h1>
      <p><strong>Back Soon!</strong></p>
    </div>
  </section>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  background-image: url(https://cdn.nishikino.xyz/rainboom-studio/static/webheader.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #333;
  height: 100vh;
}
.header-content-inner {
  color: #000;
  background: rgba(255, 255, 255, 0.5);
  padding: 50px;
  position: relative;
  top: 30%;
  margin-left: 25%;
  margin-right: 25%;
}
.header-content-inner h1 {
  font-size: 50px;
  margin-top: 0;
}
.header-content-inner p {
  font-size: 30px;
  font-weight: 300;
}
h1 {
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 10px;
}
.text-center {
  text-align: center;
}
</style>
